<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Pengaturan User Admin</h3>
  <div class="columns is-multiline text-left mb-6">
    <div class="column is-12">
      <div class="field">
        <label class="label">Id User</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.id" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">Nama</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Nama User"
            v-model="inputData.name"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Email"
            v-model="inputData.email"
          />
        </div>
        <p class="help">Digunakan untuk login</p>
      </div>

      <label class="label">Password</label>
      <div class="field has-addons">
        <div class="control has-icons-left is-expanded">
          <input
            class="input"
            :type="showPassword ? 'text' : 'password'"
            placeholder="password akun"
            v-model="inputData.password"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </div>
        <div class="control">
          <button class="button is-link" @click="showPassword = !showPassword">
            <span class="icon">
              <i
                :class="[showPassword ? 'fas fa-eye-slash' : 'fas fa-eye']"
              ></i>
            </span>
          </button>
        </div>
      </div>
      <p
        class="help text-red"
        v-if="inputData.password != inputData.confirmPassword"
      >
        Password harus sama
      </p>
      <p class="help text-red" v-if="inputData.password.length < 6">
        Minimal 6 karakter
      </p>

      <label class="label">Password (Ulangi)</label>
      <div class="field has-addons">
        <div class="control has-icons-left is-expanded">
          <input
            class="input"
            :type="showConfirmPassword ? 'text' : 'password'"
            placeholder="password akun (konfirmasi)"
            v-model="inputData.confirmPassword"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </div>
        <div class="control">
          <button
            class="button is-link"
            @click="showConfirmPassword = !showConfirmPassword"
          >
            <span class="icon">
              <i
                :class="[showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye']"
              ></i>
            </span>
          </button>
        </div>
      </div>
      <p class="help text-red" v-if="inputData.confirmPassword.length < 6">
        Minimal 6 karakter
      </p>

      <div class="field mt-4">
        <label class="checkbox">
          <input type="checkbox" v-model="inputData.edit_user" />
          Bisa Edit User
        </label>
      </div>

      <div class="field mt-4">
        <label class="checkbox">
          <input type="checkbox" v-model="inputData.enabled" />
          User Aktif
        </label>
      </div>

      <div class="field">
        <label class="label">Percobaan login gagal</label>
        <div class="control">
          <input
            class="input"
            type="number"
            min="0"
            max="3"
            placeholder="Nama User"
            v-model="inputData.failed_attempt"
          />
        </div>
      </div>
    </div>
    <div class="column">
      <button
        class="button is-danger mr-1"
        @click="cancelEdit()"
        v-bind:disabled="!editButtonStatus"
      >
        Batal Edit
      </button>
      <button class="button is-success" @click="doSave()">Simpan Data</button>
    </div>
  </div>

  <div class="mt-6">
    <Pagination :pagination="pagination" @paginate:goto="updatePage">
      <div class="table-container">
        <table
          class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left"
        >
          <thead>
            <tr>
              <th>Aksi</th>
              <th>Nama</th>
              <th>Email</th>
              <th>Edit User</th>
              <th>Aktif</th>
              <th>Login Gagal</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Aksi</th>
              <th>Nama</th>
              <th>Email</th>
              <th>Edit User</th>
              <th>Aktif</th>
              <th>Login Gagal</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(data, index) in tableData" :key="index">
              <td class="has-text-centered">
                <button class="button is-success mr-1" @click="doEdit(data)">
                  Edit
                </button>
                <button class="button is-danger" @click="doDelete(data)">
                  Hapus
                </button>
              </td>
              <td>{{data.name}}</td>
              <td>{{data.email}}</td>
              <td>{{data.edit_user}}</td>
              <td>{{data.enabled}}</td>
              <td>{{data.failed_attempt}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Pagination>
  </div>
</div>
