import { ref, onMounted } from "vue";
import { handleError } from "@/utils/errorHandler";
import UserService from "@/services/user_services";
import Pagination from "@/components/PaginationTable.vue";

export default {
  name: "User Admin",
  components: { Pagination },
  setup(props, context) {
    const inputData = ref({
      id: undefined,
      name: undefined,
      email: undefined,
      password: "",
      confirmPassword: "",
      edit_pasaran: undefined,
      edit_user: false,
      enabled: true,
      failed_attempt: 0,
    });

    const providerList = ref([
      { kode_pasaran: "", jenis_pasaran: "", checked: false },
    ]);

    const editButtonStatus = ref(false);

    const tableData = ref([
      {
        id: undefined,
        name: undefined,
        email: undefined,
        password: undefined,
        edit_pasaran: undefined,
        edit_user: undefined,
        enabled: undefined,
        failed_attempt: undefined,
      },
    ]);

    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    const pagination = ref({
      page: 1,
      limit: 40,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });

    const updatePage = (value) => {
      pagination.value.page = value;
      fetchData();
    };

    const fetchData = async () => {
      try {
        let response = await UserService.getAll(
          pagination.value.page,
          pagination.value.limit
        );
        tableData.value = response.data.data.rows;
        pagination.value.firstPage = response.data.data.firstPage;
        pagination.value.lastPage = response.data.data.lastPage;
        pagination.value.currentPage = response.data.data.currentPage;
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const doEdit = (data) => {
      inputData.value = { ...data };
      inputData.value.confirmPassword = "";
      inputData.value.password = "";

      let tempEditPasaranList = JSON.parse(data.edit_pasaran);
      for (const item of providerList.value) {
        if (tempEditPasaranList.includes(item.kode_pasaran)) {
          item.checked = true;
        }
      }

      editButtonStatus.value = true;
    };

    const cancelEdit = () => {
      inputData.value.id = undefined;
      editButtonStatus.value = false;
    };

    const doDelete = async (data) => {
      let confirmation = confirm(
        `Yakin menghapus data ${data.username}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await UserService.deleteData(data.id);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doSave = () => {
      if (
        inputData.value.password != inputData.value.confirmPassword ||
        !inputData.value.password
      ) {
        alert("Password tidak boleh kosong dan/atau harus sama");
        return;
      }
      if (inputData.value.length < 6) {
        alert("Password minimal 6 karakter");
      }
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        inputData.value.edit_pasaran = JSON.stringify(
          providerList.value.map((item) => item.kode_pasaran)
        );
        if (inputData.value.id) {
          updateExistingData();
        } else {
          createNewData();
        }
        cancelEdit();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        let response = await UserService.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        console.log("ERROR :::", error);
        handleError(context, error);
      }
    };

    const updateExistingData = async () => {
      try {
        let response = await UserService.updateData(
          inputData.value.id,
          inputData.value
        );
        if (response.data.success) {
          fetchData();
          alert("Data berhasil diubah");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    return {
      inputData,
      tableData,
      pagination,
      editButtonStatus,
      showPassword,
      showConfirmPassword,
      providerList,
      updatePage,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,
    };
  },
};
